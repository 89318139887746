var PeopleMediaMenu = (function ($jq) {
    "use strict";
    var pub = [];
    var delayTimer;
    var currentTab;
    var reset = function () {
        try {
            if (delayTimer != null) {
                clearTimeout(delayTimer);
            }
            delayTimer = null;
            var $container = $jq("#menu-container, #menu-right-section");
            $container.find(".sub-menu-item").hide();
            $container.find(".menu-item").find("> a").removeClass("active");
            if (currentTab && currentTab.length > 0) {
                currentTab.addClass("active");
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { method: "PeopleMediaMenu.reset", currentTab: currentTab });
        }
    };
    var fadeOutMenu = function (parentMenu, immediate) {
        try {
            if (!immediate || typeof (immediate) !== "boolean") {
                immediate = false;
            }
            var name = parentMenu.prop('id');
            var subTab = parentMenu.find(".sub-menu-item").first();
            if (subTab.is(":visible")) {
                if (delayTimer != null) {
                    clearTimeout(delayTimer);
                }
                delayTimer = setTimeout(function () {
                    subTab.fadeOut("fast");
                    parentMenu.find("> a").removeClass("active");
                    if (currentTab && currentTab.length > 0) {
                        currentTab.addClass("active");
                    }
                    subTab.attr("aria-expanded", "false");
                }, immediate ? 0 : 1000);
            } else {
                parentMenu.find("> a").removeClass("active");
                if (currentTab && currentTab.length > 0) {
                    currentTab.addClass("active");
                }
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { method: "PeopleMediaMenu.fadeOutMenu", currentTab: currentTab });
        }
    };
    var showMenu = function (parentMenu) {
        try {
            var name = parentMenu.prop('id');
            var subTab = parentMenu.find(".sub-menu-item").first();
            if (subTab.is(":visible")) {
                if (delayTimer != null) {
                    clearTimeout(delayTimer);
                }
                delayTimer = null;
                return;
            }
            reset();
            subTab.fadeIn("fast");
            parentMenu.find(" > a").addClass("active");
            subTab.attr("aria-expanded", "true");
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { method: "PeopleMediaMenu.showMenu", currentTab: currentTab });
        }
    };
    var showSettingsMenu = function() {
        showMenu($jq("#menu-settings"));
    };
    var keyboardNavigationHandler = function(e) {
        if (e && typeof (e) === "object") {
            var parentMenu = $jq(this).closest(".menu-item");

            // left arrow
            if (e.which === 37) {
                e.preventDefault();
                var navItems = $jq(".keyboard-navigable-menu-item");
                var index = navItems.index(e.currentTarget);

                if (index > 0) {
                    navItems[index - 1].focus();
                    fadeOutMenu(parentMenu, true);
                }

            }
            // right arrow
            if (e.which === 39) {
                e.preventDefault();
                var navItems = $jq(".keyboard-navigable-menu-item");
                var index = navItems.index(e.currentTarget);

                if (index < navItems.length - 1) {
                    navItems[index + 1].focus();
                    fadeOutMenu(parentMenu, true);
                }
            }
            // up arrow
            if (e.which === 38) {
                e.preventDefault();

                // if not on main nav icon, then allow focusing on previous sibling
                if (e.target !== e.currentTarget) {
                    var prev = $jq(e.target).prev("a");
                    if (prev.length) {
                        prev.focus();
                    } else {
                        parentMenu.focus();
                        fadeOutMenu(parentMenu, true);
                    }
                }
            }
            // down arrow
            if (e.which === 40) {
                e.preventDefault();

                if (e.target === e.currentTarget) {
                    showMenu(parentMenu);
                    $jq(e.currentTarget).find("a").first().focus();
                } else {
                    $jq(e.target).next("a").focus();
                }
            }
        }
    };
    pub.init = function () {
        try {
            var $container = $jq("#menu-container, #menu-right-section");
            currentTab = $container.find(".active").first();
            var subMenus = $container.find(".sub-menu-item").hide();
            var allTabs = $container.find(".menu-item")
                .on("mouseenter", function (event) {
                    var parentMenu = $jq(this).closest(".menu-item");
                    showMenu(parentMenu);
                })
                .on("mouseleave", function (event) {
                    var parentMenu = $jq(this).closest(".menu-item");
                    fadeOutMenu(parentMenu);
                })
                .on("focusout", function (event) {
                    // fade out sub-menu if keyboard navigation away from sub-menu happens
                    if (!event.currentTarget.contains(event.relatedTarget)) {
                        var parentMenu = $jq(this).closest(".menu-item");
                        fadeOutMenu(parentMenu);
                    }
                });

            // accessibility listeners
            $jq(".headerV2").on("keydown", ".keyboard-navigable-menu-item", keyboardNavigationHandler);
            $jq(".headerV2 #menu-settings").on("keypress", { 'callback': showSettingsMenu }, PeopleMedia.AccessibilityHelper.checkForEnterOrSpaceKeypress);
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { method: "PeopleMediaMenu.init", currentTab: currentTab });
        }
    };
    return pub;
})(jQuery);
jQuery(function () {
    "use strict";
    PeopleMediaMenu.init(jQuery);
});
